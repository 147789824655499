#main-tabpanel-1,
#users-tabpanel-0,
#users-tabpanel-1 {
  > div {
    padding: 0;
  }
}

.users-page {
  .tabs-right {
    .MuiTabs-flexContainer {
      justify-content: end;

      button {
        padding: 10px;
        min-width: unset;
        min-height: unset;
      }
    }
  }

  .MuiStepper-horizontal {
    margin-bottom: 30px;
  }

  .form-container {
    > .MuiGrid-container > .MuiGrid-item {
      width: 100%;
      > div > .MuiToolbar-regular {
        padding: 0 25px;
      }
    }
  }

  .json-form
    > .MuiGrid-container
    > .MuiGrid-item
    > div
    > div
    > .MuiPaper-elevation
    > .MuiButtonBase-root {
    .Mui-expanded {
      margin-bottom: 12px;
      margin-top: 12px;
    }
  }

  .json-form
    > .MuiGrid-container
    > .MuiGrid-item
    > div
    > div
    > .MuiPaper-elevation
    > .MuiCollapse-root
    > .MuiCollapse-wrapper
    > .MuiCollapse-wrapperInner
    > .MuiAccordion-region {
    .MuiAccordionDetails-root > .MuiGrid-container {
    }

    > .MuiAccordionDetails-root > .MuiGrid-container > .MuiGrid-item {
      width: 100%;

      &:nth-child(1) {
        margin-bottom: 15px;

        > .MuiGrid-container > .MuiGrid-item:nth-child(2) > .MuiGrid-container {
          display: block;
          > .MuiGrid-item {
            // width: calc(33.33% - 20px);
            // float: left;
            // margin-left: 10px;
            // margin-right: 10px;
            // min-height: 80px;

            > .MuiTextField-root {
              margin-top: 5px;
              margin-bottom: unset;
            }
          }
        }
      }

      &:nth-child(2) {
        > .MuiGrid-container > .MuiGrid-item {
          width: 100%;

          &:nth-child(1) {
            margin-bottom: 15px;
          }

          &:nth-child(2)
            > div
            > .MuiGrid-container
            > .MuiGrid-item
            > .MuiGrid-container
            > .MuiGrid-item
            > div {
            display: flex;
            flex-direction: column;
            padding: 0 15px;

            > div
              > .MuiPaper-elevation
              > .MuiCollapse-root
              > .MuiCollapse-wrapper
              > .MuiCollapse-wrapperInner
              > .MuiAccordion-region {
              .MuiAccordionDetails-root > .MuiGrid-container {
              }
              > .MuiAccordionDetails-root > .MuiGrid-container {
                width: 100%;
                display: inline-block;

                > .MuiGrid-item {
                  // width: calc(33.33% - 20px);
                  // float: left;
                  // margin-left: 10px;
                  // margin-right: 10px;
                  // min-height: 80px;

                  > .MuiTextField-root {
                    margin-top: 5px;
                    margin-bottom: unset;
                  }
                }

                &:nth-child(1) {
                  margin-bottom: 15px;

                  > .MuiGrid-container
                    > .MuiGrid-item:nth-child(2)
                    > .MuiGrid-container {
                    display: block;
                    > .MuiGrid-item {
                      width: calc(33.33% - 20px);
                      float: left;
                      margin-left: 10px;
                      margin-right: 10px;

                      > .MuiTextField-root {
                        margin-top: 5px;
                        margin-bottom: unset;
                      }
                    }
                  }
                }
              }
            }

            > div > .MuiPaper-elevation.MuiPaper-rounded {
              .MuiAccordionDetails-root {
                padding: 0 10px;
              }
            }

            > .MuiToolbar-root {
              order: 2;
            }
          }
        }

        .MuiGrid-container > .MuiGrid-item:nth-child(2) > .MuiGrid-container {
          display: block;
          > .MuiGrid-item {
            width: calc(33.33% - 20px);
            float: left;
            margin-left: 10px;
            margin-right: 10px;

            > .MuiTextField-root {
              margin-top: 5px;
              margin-bottom: unset;
            }
          }
        }
      }
    }
  }
}
