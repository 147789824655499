.custom-preview {
  padding-left: 20px;
}

.custom-preview-data-content {
  display: flex;
  justify-content: center;
  border-radius: 0.25em;
  background-color: #cecece;
  margin-bottom: 1rem;
  overflow: hidden;
  overflow-x: scroll;
  font-size: 13px;
}

.custom-preview-title {
  text-align: center;
  padding: 0.25em;
}
