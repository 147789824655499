.app-container .main-container {
  max-width: 1100px;
}

.services-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.main-tabs {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.main-tabs .MuiTabs-indicator {
  background-color: #1976d2 !important;
}
