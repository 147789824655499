html {
  font-size: 16px;
}

.App {
  text-align: center;
  margin-bottom: 0.5em;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.generate-json-btn {
  margin-left: 20px;
}
.app-container {
  display: flex;
  justify-content: center;
}

#main-tabpanel-2 > div {
  padding-left: unset;
  padding-right: unset;
}

#services-tabpanel-0 > div,
#services-tabpanel-1 > div,
#services-tabpanel-2 > div,
#services-tabpanel-3 > div,
#services-tabpanel-4 > div,
#services-tabpanel-5 > div,
#services-tabpanel-6 > div {
  padding-left: unset;
  padding-right: unset;
}

.json-form .MuiStack-root {
  width: 100%;
}

.json-form > .MuiGrid-container > .MuiGrid-item > div {
  display: flex;
  flex-direction: column;
}

.json-form > .MuiGrid-container > .MuiGrid-item > div > div {
  order: 1;
}

.json-form > .MuiGrid-container > .MuiGrid-item > div > .MuiToolbar-root {
  order: 2;
}

.json-form .MuiAccordionSummary-content > .MuiGrid-container {
  flex-wrap: nowrap;
}

.json-form
  .MuiAccordionSummary-content
  > .MuiGrid-container
  > .MuiGrid-item:first-child {
  flex-basis: unset;
  width: 90%;
  max-width: unset;
}

.json-form
  .MuiAccordionSummary-content
  > .MuiGrid-container
  > .MuiGrid-item:first-child
  > .MuiGrid-container {
  flex-wrap: nowrap;
}

.json-form
  .MuiAccordionSummary-content
  > .MuiGrid-container
  > .MuiGrid-item:first-child
  > .MuiGrid-container
  > .MuiGrid-item {
  flex-basis: unset;
  max-width: unset;
}

.json-form
  .MuiAccordionSummary-content
  > .MuiGrid-container
  > .MuiGrid-item:first-child
  > .MuiGrid-container
  > .MuiGrid-item:last-child {
  margin-left: 10px;
}

.json-form .MuiAccordionSummary-content > .MuiGrid-container > .MuiGrid-item {
  flex-basis: unset;
  width: 10%;
  max-width: unset;
}

.logout-btn {
  margin-left: 10px !important;
}

.login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 150px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.login-footer {
  justify-content: center;
  display: flex;
}

.login-container h2 {
  margin: unset;
  text-align: center;
}

.login-container h3 {
  margin: unset;
  text-align: center;
}

.login-container p {
  text-align: center;
  font-weight: 500;
}

.login-footer p {
  margin-bottom: unset;
}

.main-container {
  width: 100% !important;
  max-width: "1200px";
  padding-bottom: "50px";
}

.MuiFormHelperText-root {
  display: flex;
  order: 2;
}

.MMuiAppBar-root {
  background-color: #5b6b7b !important;
}

.MuiTabs-indicator {
  background-color: #fff !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
