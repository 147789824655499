@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

body {
  font-family: "Ubuntu", sans-serif !important;
}

.form-page,
.custom-json-form h4,
.custom-preview h4,
.custom-preview,
.MuiButtonBase-root,
.MuiInputBase-root,
.MuiFormLabel-root,
.MuiInputAdornment-root,
.MuiFormHelperText-root,
.MuiTypography-root,
.MuiTab-root {
  font-family: "Ubuntu", sans-serif !important;
}
